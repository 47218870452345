// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-servererror-js": () => import("./../../../src/pages/servererror.js" /* webpackChunkName: "component---src-pages-servererror-js" */),
  "component---src-templates-blog-details-index-js": () => import("./../../../src/templates/blog-details/index.js" /* webpackChunkName: "component---src-templates-blog-details-index-js" */),
  "component---src-templates-blog-index-js": () => import("./../../../src/templates/blog/index.js" /* webpackChunkName: "component---src-templates-blog-index-js" */),
  "component---src-templates-careers-index-js": () => import("./../../../src/templates/careers/index.js" /* webpackChunkName: "component---src-templates-careers-index-js" */),
  "component---src-templates-casestudy-detail-index-js": () => import("./../../../src/templates/casestudy-detail/index.js" /* webpackChunkName: "component---src-templates-casestudy-detail-index-js" */),
  "component---src-templates-contact-us-index-js": () => import("./../../../src/templates/contact-us/index.js" /* webpackChunkName: "component---src-templates-contact-us-index-js" */),
  "component---src-templates-generic-template-index-js": () => import("./../../../src/templates/generic-template/index.js" /* webpackChunkName: "component---src-templates-generic-template-index-js" */),
  "component---src-templates-home-index-js": () => import("./../../../src/templates/home/index.js" /* webpackChunkName: "component---src-templates-home-index-js" */),
  "component---src-templates-payment-orphan-page-index-js": () => import("./../../../src/templates/payment-orphan-page/index.js" /* webpackChunkName: "component---src-templates-payment-orphan-page-index-js" */),
  "component---src-templates-service-detail-index-js": () => import("./../../../src/templates/service-detail/index.js" /* webpackChunkName: "component---src-templates-service-detail-index-js" */),
  "component---src-templates-services-index-js": () => import("./../../../src/templates/services/index.js" /* webpackChunkName: "component---src-templates-services-index-js" */),
  "component---src-templates-subprocessors-index-js": () => import("./../../../src/templates/subprocessors/index.js" /* webpackChunkName: "component---src-templates-subprocessors-index-js" */),
  "component---src-templates-who-we-are-index-js": () => import("./../../../src/templates/who-we-are/index.js" /* webpackChunkName: "component---src-templates-who-we-are-index-js" */),
  "component---src-templates-work-index-js": () => import("./../../../src/templates/work/index.js" /* webpackChunkName: "component---src-templates-work-index-js" */)
}

